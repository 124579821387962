import { IAnalystFilterConfigDto } from "@components/Insights/Analyst/AnalystModel";
import { ISearchDimensionMembersResult } from "@components/Filters/AxisModel";

export interface IFilterData {
	dataType: FilterDataType;
}

export interface IRollingFilterData extends IFilterData {
	level: LevelType;
	value: number;
	enableOffset: boolean;
	offsetValue: number;
	offsetLevel: LevelType;
}

export interface IOriginalDate {
	year: number;
	month: number;
	quarter: number;
}

export interface IMemberIdentity {
	caption: string;
	name: string;
	date: IOriginalDate;
}

export interface ILevelMetadata {
	parentMember: string | null;
	members: Array<IMemberIdentity>;
}

export interface IMemberFilterData extends IFilterData {
	member: IMemberIdentity;
}

export interface IDimensionIdentity {
	caption: string;
	dimensionName?: string;
	dimensionHierarchy: string;
	axisId: string;
}

export interface IDimensionMetadata {
	defaultTopLevelMember: string;
	currentMember: string;
	identity: IDimensionIdentity;
	data: FilterDataset | null;
}

export enum ComparePeriod {
	PreviousPeriod = "PreviousPeriod",
	PreviousYear = "PreviousYear"
}

export interface IInsightFilterState {
	settings: IInsightsFilterSettings;
	identity: IDimensionIdentity;
	data: IFilterData | null;
}

export interface IDialogFilterSettings {
	reportId: string;
	stateId: string;
	toggleDialog: () => void;
	isOpen: boolean;
	selectedFilter?: IAnalystFilterConfigDto;
	filters: IAnalystFilterConfigDto[];
}

export interface IFilterOptionsProps {
	filterState: IInsightFilterState;
	samePeriodEnabled: boolean;
	isPrimaryDimension: boolean;
	dimension: IDimensionIdentity;
	onSettingsChanged: (settings: IInsightsFilterSettings) => void;
}

export enum LevelType {
	Year = "Year",
	Quarter = "Quarter",
	Month = "Month"
}

export enum RunOutType {
	SamePeriod = "SamePeriod",
	AdditionalMonths = "AdditionalMonths"
}

export interface IRunOutSettings {
	type: RunOutType;
	monthsCount: number | null;
	axisId: string | null;
	isEnabled: boolean;
}
export interface IInsightsFilterSettings {
	comparePeriod: ComparePeriod;
	runOut: IRunOutSettings | null;
}

export interface IInsightsFilterMetadata {
	dimensions: Array<IDimensionMetadata>;
	primaryHierarchyName: string | null;
}

export interface IFilterDialogTabPanelProps {
	filterState: IInsightFilterState;
	dimension: IDimensionMetadata;
	isPrimary: boolean;
	setFilterStateHandler: (state: IInsightFilterState) => void;
	resetFilerHandler: () => void;
}

export enum FilterDataType {
	Include = "Include",
	Range = "Range"
}

export enum Quarters {
	Q1 = 1,
	Q2 = 2,
	Q3 = 3,
	Q4 = 4
}

export const ROLLING_FILTER_INITIAL_STATE = {
	dataType: FilterDataType.Range,
	level: LevelType.Month,
	value: 1,
	enableOffset: false,
	offsetLevel: LevelType.Month,
	offsetValue: 1
} as IRollingFilterData;

export interface IFilterDataModel {
	isConfigurationLoaded: boolean;
	metadata: IInsightsFilterMetadata;
	defaultFilter: IInsightFilterState | null;
	appliedFilterState: IInsightFilterState | null;
	membersSearchResult: Dictionary<ISearchDimensionMembersResult> | null;
}

export interface IFilterBadge {
	firstLabel: string;
	secondLabel: string;
	separator?: string;
}

export class FilterBadge implements IFilterBadge {
	private readonly DEFAULT_SEPARATOR: string = "Vs.";
	public firstLabel: string;
	public secondLabel: string;
	public separator?: string;

	constructor(firstLabel: string, secondLabel: string, separator?: string) {
		this.firstLabel = firstLabel;
		this.secondLabel = secondLabel;
		this.separator = separator ?? this.DEFAULT_SEPARATOR;
	}

	public toString() {
		return `${this.firstLabel} ${this.separator} ${this.secondLabel}`;
	}
}

export type FilterDataset = Record<LevelType, Array<ILevelMetadata>>;

export interface ITimeFilterDto {
	type: FilterDataType;
	axisId: string;
	compareType: ComparePeriod;
	includeMember?: string;
	rangeCount?: number;
	rangeLevel?: string;
	rangeLag?: number | null;
	rangeLagLevel?: string | null;
	runOutType?: RunOutType | null;
	runOutAxisId?: string | null;
	runOutMonthsCount?: number | null;
}

export interface IDrillAxisDto {
	id: string;
	member?: string;
	level?: string | null;
}

export interface IInsightsTimeFilterConfigDto {
	metadata: ITimeMetadataDto;
	defaultFilter: ITimeFilterDto;
}

export interface IInsightsConfigurationDto extends IInsightsTimeFilterConfigDto {
	postDate: IPostDateDto;
}

export interface ITimeMetadataDto {
	primaryHierarchyName: string;
	dimensions: ITimeAxisMetadataDto[];
}

export interface ITimeAxisMetadataDto {
	identity: ITimeAxisIdentityDto;
	levels: Dictionary<IMemberIdentity[]>;
}

export interface ITimeAxisIdentityDto {
	caption: string;
	dimensionHierarchy: string;
	axisId: string;
}

export interface IPostDateDto {
	info: IPostDateInfoDto;
	additionalInfo: IPostDateInfoDto | null;
}

export interface IPostDateInfoDto {
	title: string;
	value: string;
}
