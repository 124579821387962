import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "@redux/reduxHooks";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import PageNotFound from "@components/PageNotFound";
import { ErrorContent } from "./ErrorContent";
import { withRouteProtection } from "./WithRouteProtection";
import { MedeSpinner } from "@mede/react-library/components";
import { EpmRouter } from "@epm/epmRouter";

const SummaryPage = lazy(() => import("@components/Summary/SummaryPage"));
const InsightsPage = lazy(() => import("@components/Insights/Panel/InsightsPage"));
const AnalystBoard = lazy(() => import("@components/Insights/Analyst/AnalystBoard"));
const AlertsPage = lazy(() => import("@components/Alerts/AlertsPage"));
const FileManagerPage = lazy(() => import("@components/FileManager/FileManagerPage"));
const CohortsPage = lazy(() => import("@components/Cohorts/CohortsPage"));
const CohortDetailsPage = lazy(() => import("@components/Cohorts/CohortDetailsPage"));
const BoardPage = lazy(() => import("@components/Board/BoardPage"));
const EpmPage = lazy(() => import("@epm/EpmApp"));

export default function PageContent() {
	const isReady = useAppSelector(s => s.app.configuration.hasAccess);
	const settings = useAppSelector(s => s.menu.menuData.settings);

	if (!isReady) {
		return (
			<div className="page-content">
				<Loader />
			</div>
		);
	}

	const InsightPageComponent = withRouteProtection(settings.hasInsightsAccess, InsightsPage);
	const AnalystBoardComponent = withRouteProtection(settings.hasInsightsAccess, AnalystBoard);
	const CohortsPageComponent = withRouteProtection(settings.hasCohortsAccess, CohortsPage);
	const CohortDetailsPageComponent = withRouteProtection(settings.hasCohortsAccess, CohortDetailsPage);
	const EpmComponent = withRouteProtection(settings.hasMedeAchieveAccess, EpmPage);

	return (
		<div className="page-content thin-scroll">
			<ErrorBoundary fallback={<ErrorContent />}>
				<Suspense fallback={<Loader />}>
					<Routes>
						<Route path="insights" element={<InsightPageComponent />} />
						<Route path="insights/:insightId" element={<AnalystBoardComponent />} />
						<Route path="cohorts" element={<CohortsPageComponent />} />
						<Route path="cohorts/:cohortId" element={<CohortDetailsPageComponent />} />
						<Route path="alerts" element={<AlertsPage />} />
						<Route path="file-manager" element={<FileManagerPage />} />
						<Route path="SummaryReport/fav/:favoriteId" element={<SummaryPage />}>
							<Route path=":storedStateId" element={<SummaryPage />} />
						</Route>
						<Route path="SummaryReport/:reportId/:stateId" element={<SummaryPage />}>
							<Route path=":storedStateId" element={<SummaryPage />} />
						</Route>
						<Route path="Board/fav/:id/:favoriteId/:favoriteItemId" element={<BoardPage />}>
							<Route path=":stateId" element={<SummaryPage />} />
						</Route>
						<Route path="Board/:id" element={<BoardPage />}>
							<Route path=":stateId" element={<SummaryPage />} />
						</Route>
						<Route path={`${EpmRouter.ROOT}/*`} element={<EpmComponent />} />

						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Suspense>
			</ErrorBoundary>
		</div>
	);
}

const Loader = () => <MedeSpinner className="page-spinner" aria-label="Loading page" intent="primary" size={"l"} />;
