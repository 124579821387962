import { combineSlices, configureStore } from "@reduxjs/toolkit";
import trackerReducer from "@common/tracker/trackerSlice";
import menuReducer from "@components/Menu/menuSlice";
import appReducer from "@components/App/appSlice";
import dialogReducer from "@components/Dialog/dialogSlice";
import { Reducer, UnknownAction } from "redux";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface LazyLoadedSlices {}

export const rootReducer = combineSlices({
	app: appReducer,
	menu: menuReducer,
	dialog: dialogReducer,
	tracker: trackerReducer
}).withLazyLoadedSlices<LazyLoadedSlices, true>();

let lastStore: AppStore | undefined;
export default function setupStore(preloadedState?: Partial<RootState>) {
	const store = configureStore({
		reducer: rootReducer,
		preloadedState,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredActions: ["dialog/openDialog"],
					ignoredPaths: ["dialog"]
				}
			})
	});

	lastStore = store;
	return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function injectSlice(slice: any) {
	rootReducer.inject(slice);
	// If store was already created by the time of injection, update state to include newly added slice
	lastStore?.dispatch({ type: "@@INJECT_" + slice.name });
}

type Id<T> = {
	[K in keyof T]: T[K];
} & object;

declare module "@reduxjs/toolkit" {
	// When updating Blueprint, ensure that CombinedSliceReducer has the same signature
	interface CombinedSliceReducer<InitialState, DeclaredState = InitialState>
		extends Reducer<DeclaredState, UnknownAction, Partial<DeclaredState>> {
		// Keep Lazy non-partial. Add second generic param to ensure this implementation is used
		withLazyLoadedSlices<Lazy, _T>(): CombinedSliceReducer<InitialState, Id<DeclaredState & Lazy>>;
	}
}
