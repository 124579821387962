export const PRODUCT_ID_SESSION_KEY = "product-id";
export const MENU_BUCKET_CAPTION_SESSION_KEY = "menu-bucket-caption";
export const BREADCRUMB_SESSION_KEY = "bread-crumbs-data";
export const MENU_SESSION_KEY = "menu-cache";
export const UPDATE_MENU_CACHE_KEY = "update-menu-cache";
export const AUTH_ID_KEY = "auth-id";
export const LEFT_MENU_ENABLED_KEY = "left-menu-enabled";
export const CLIENT_TITLE_SESSION_KEY = "client-title";
export const COHORTS_FILTERS_SESSION_KEY = "cohorts-filters";
export const COHORTS_SORT_SESSION_KEY = "cohorts-sort";
export const CLEANUP_MODULE_DATA = "cleanup-module-data";
export const XCSRF_TOKEN_KEY = "xcsrf-token";
export const THEME_KEY = "theme";
export const HOME_PAGE = "_Home_";
export const HOME_PAGE_NAME = "Home";
export const FAVORITES_PAGE_CAPTION = "Favorites";
export const WORK_LISTS_PAGE_CAPTION = "Work Lists";
export const EPM_APP = "epm_web";
export const EXTERNAL_URL_ARRAY_KEY = "external-url-array";
export const REPLACE_QUERY_PARAM = "replaceHistory=true";
export const SAVE_RETURN_QUERY_PARAM = "save-return=true";
export const CANCEL_RETURN_QUERY_PARAM = "cancel-return=true";
export const SESSION_EXPIRATION_COOKIE_NAME = "session_exp";
export const UPDATE_SESSION_STATE_METHOD = "updateSessionState";
export const UPDATE_SESSION_ABOUT_TO_EXPIRE_STATE = "updateAboutToExpireState";
export const NOT_VIEWED_EVENTS = "notViewedEvents";
export const ALERTS_COUNT_EVENT = "alertsCount";
export const PENDO_OPTIONS = "pendo-options";
export const NOT_VIEWED_ALERTS_KEY = "not-viewed-alerts";
export const NOT_VIEWED_COLLABORATIONS_KEY = "not-viewed-collaborations";
export const LOGGED_OUT_MESSAGE = "You have been logged out. Please login.";
export const LAST_TIME_FILTER_SESSION_KEY = "last-time-filter";
export const BLUEPRINT_DARK = "bp5-dark";
export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
export const SESSION_ABOUT_TO_EXPIRE_MESSAGE =
	"Your session is about to expire. Click the 'Continue' button to proceed";
export const SESSION_ABOUT_TO_EXPIRE_NOTIFICATION_TIMEOUT = 120;
export const WRONG_LAYOUT_ERROR = "The file does not match the required template layout.";
export const VIEW_FULL_SUMMARY_REPORT_LABEL =
	"Showing top results. For a full list, please navigate to the summary report.";

export class Metric {
	public static readonly POST_DATE_INFO = "post-date-info";
	public static readonly EPM_NULLABLE_SCORE = "epm-nullable-score";
	public static readonly HAS_HISTORICAL_CFG = "has-historical-cfg";
	public static readonly EPM_DATA_FORMAT: string = "epm-data-format";
}
