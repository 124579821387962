import { MedeAnchorButton, MedeErrorPage } from "@mede/react-library/components";
import { faHeadset } from "@mede/react-library/icons";

export function ErrorContent() {
	return (
		<MedeErrorPage
			title="500"
			subtitle="Server Error"
			description="Try to  refresh this page or feel free to contact us if the problem persist."
		>
			<MedeAnchorButton
				large
				outlined
				text="Contact Support"
				icon={faHeadset}
				href="https://medeanalytics.com/support/"
				target="_blank"
			/>
			<MedeAnchorButton large intent={"primary"} text="Back to Homepage" href="/LandingPage" />
		</MedeErrorPage>
	);
}
