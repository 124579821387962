import { ISummaryParams } from "@components/Summary/summary-types";
import { IPivotTableModel, IPivotTableSortSettings } from "@components/Summary/PivotTableModel";

export const defaultSummaryState = {
	reportParams: undefined as ISummaryParams | undefined,
	table: {
		isLoading: false,
		isFailed: false,
		data: undefined as IPivotTableModel | undefined
	},
	sorts: [] as IPivotTableSortSettings[]
};
