import { CANCEL_RETURN_QUERY_PARAM, REPLACE_QUERY_PARAM, SAVE_RETURN_QUERY_PARAM } from "./constants";
import { saveCaptionInHistory } from "@components/Menu/menuHistoryStateUtils";
import { useAppSelector } from "@redux/reduxHooks";
import { FilterDataType } from "@components/Insights/FilterPanel/InsightsFilterPanelModels";
import type { FontAwesomeIconDto } from "@mede/react-library/core";

export function openNewWindow(url: string, features?: string): void {
	const w = window.open(url, "_blank", features);
	w?.focus();
}

export function isBackForwardNavigation(): boolean {
	return getBrowserNavigationType() === "back_forward";
}

export function isReloadNavigation(): boolean {
	return getBrowserNavigationType() === "reload";
}

export function isLinkNavigation(): boolean {
	return !!document.referrer;
}

export function isReplaceNavigation(): boolean {
	return window.location.search.includes(REPLACE_QUERY_PARAM);
}

export function isSaveNavigation(): boolean {
	return window.location.search.includes(SAVE_RETURN_QUERY_PARAM);
}

export function isCancelNavigation(): boolean {
	return window.location.search.includes(CANCEL_RETURN_QUERY_PARAM);
}

export function isOpenUrlNavigation(): boolean {
	return !document.referrer;
}

export function getBrowserNavigationType(): NavigationTimingType | null {
	return (performance?.getEntriesByType?.("navigation")[0] as PerformanceNavigationTiming)?.type;
}

export function setXcsrfToken(xcsrfToken: string | null, isLegacyAppMode: boolean) {
	if (!xcsrfToken) {
		return;
	}

	window.xcsrfHeaderValue = xcsrfToken;
	if (isLegacyAppMode && window.$) {
		window.$.ajaxSetup({
			headers: {
				"X-Csrf-Token": xcsrfToken
			}
		});
	}
}

export function setDocumentTitle(clientTitle: string, caption: string) {
	document.title = buildClientTitle(clientTitle, caption);
	saveCaptionInHistory(document.title);
}

export function buildClientTitle(clientTitle: string | null, caption: string): string {
	if (!clientTitle) {
		return caption;
	}

	return `${clientTitle} - ${caption}`;
}

export const useIsRangeFilter = (): boolean => {
	const filter = useAppSelector(s => s.insight.filterData.appliedFilterState);
	return filter?.data?.dataType === FilterDataType.Range;
};

export function formatDate(date: Date): string {
	return date.toLocaleDateString("en-US");
}

export function formatString(pattern: string, values: Dictionary<string | null> | (string | null)[]) {
	let result = pattern;
	if (Array.isArray(values)) {
		for (let ai = 0; ai < values.length; ++ai) {
			result = result.replace(RegExp("\\{" + ai + "\\}", "gi"), values[ai] ?? "");
		}
	} else if (typeof values === "object") {
		for (const key in values) {
			result = result.replace(RegExp("\\{" + key + "\\}", "gi"), values[key] ?? "");
		}
	}

	return result;
}

export function getTimeAgoDescription(date: Date) {
	const now = new Date();
	const diff = now.getTime() - date.getTime();

	const seconds = Math.floor(diff / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(days / 30);
	const years = Math.floor(days / 365);

	if (years > 0) {
		return getTimeAgoText(years, "year");
	} else if (months > 0) {
		return getTimeAgoText(months, "month");
	} else if (weeks > 0) {
		return getTimeAgoText(weeks, "week");
	} else if (days > 0) {
		return getTimeAgoText(days, "day");
	} else if (hours > 0) {
		return getTimeAgoText(hours, "hour");
	} else if (minutes > 0) {
		return getTimeAgoText(minutes, "minute");
	} else {
		return getTimeAgoText(seconds, "second");
	}
}

function getTimeAgoText(value: number, unit: string) {
	return `${value} ${unit}${value > 1 ? "s" : ""} ago`;
}

export function areArraysEqual<T>(a: T[], b: T[]) {
	if (a.length != b.length) {
		return false;
	}

	for (let i = 0; i < a.length; i++) {
		if (a[i] != b[i]) {
			return false;
		}
	}

	return true;
}

interface IconDefinition {
	prefix: string;
	iconName: string;
	icon: [
		number, // width
		number, // height
		string[], // ligatures
		string, // unicode
		string | string[] // svgPathData
	];
}

export function buildFontAwesomeIconDto(icon: IconDefinition): FontAwesomeIconDto {
	const path = icon.icon[4];
	return {
		name: icon.iconName,
		width: icon.icon[0],
		height: icon.icon[1],
		paths: typeof path == "string" ? [path] : path
	};
}
