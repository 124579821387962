export namespace LegacyAppUrls {
	export const favorites = "/analytics/Managers/FavoriteManager.mvc/Index?favoritesType=";
	export const workLists = "/analytics/Managers/CustomDetailManager.mvc/Manager?type=";
	export const userGroups = "/analytics/Managers/UserGroups.mvc";
	export const addCollaborationDialog = "/analytics/Managers/CollaborationManager.mvc/ShowAddDialog?";
	export const landingPage = "/LandingPage";
	export const customDetail = "analytics/Detailed4G/Detailed4G.mvc?customDetailItemId=";
	export const getPendoOptions = "/StartPage/StartPage/GetPendoOptions";
	export const getExternalUrls = "/platform-api/configuration/external-urls";
	export const startPage = "/StartPage";
	export const logout = "/logout.aspx";
	export const logoutByOtherSession = "/errors/userLogoff.aspx?SessionState=LoggedOutByOtherSession";
	export const externalCss = "/app/external/menu-external.css";
	export const getSystemAlertById = "/StartPage/StartPage/GetSystemAlert?alertId=";
	export const editAlertDialog = "/analytics/AnalyticsControls/AlertEditor.mvc/EditAlert?alertId=";
	export const saveAlertDialog = "/analytics/AnalyticsControls/AlertEditor.mvc/SaveAlert";
	export const viewSummaryFavorite = "/analytics/Summary4G/Summary4G.mvc/Favorite?favoriteId=";
}
