import "./Breadcrumb.scss";
import { Breadcrumbs as BPBreadcrumbs, BreadcrumbProps } from "@blueprintjs/core";
import { useAppSelector } from "@redux/reduxHooks";

export default function Breadcrumbs(props: Readonly<{ items: BreadcrumbProps[] }>) {
	const { info, additionalInfo } = useAppSelector(s => s.menu.postDate);

	return (
		<div className="container-bread">
			<div id="breadCrumbsContainer" style={{ paddingRight: "15px" }}>
				<BPBreadcrumbs items={props.items} minVisibleItems={10} />
			</div>
			<div>
				<div id="post-date-info">{info}</div>
				<div id="post-date-additional-info">{additionalInfo}</div>
			</div>
		</div>
	);
}

export class BreadcrumbStoredItem {
	path: Array<string>;
	caption: string;
	pageVersion: number;
	uri: string | null;
	group: string | null;
	historyOffset: number;
	nonHistory: boolean;
	pathRootUri?: string | null;

	constructor(
		caption: string,
		path: string | string[],
		uri: string | null = null,
		group: string | null = null,
		pageVersion: number = 0,
		historyOffset: number = 0,
		nonHistory: boolean = false
	) {
		this.caption = caption;
		this.path = Array.isArray(path) ? path : [path];
		this.uri = uri;
		this.group = group;
		this.pageVersion = pageVersion;
		this.historyOffset = historyOffset;
		this.nonHistory = nonHistory;
	}
}
