import {
	FetchStatus,
	IColoredDataDto,
	IEnvironment,
	IFetchableItem,
	IFetchableItems,
	IOrganization,
	IPlanSet,
	IUser
} from "@epm/epmModel";
import { IIdentifiableWithCaption } from "@epm/Controls/BaseModels";

export const EMPTY_PLAN_SET: IPlanSet = { planSetId: 0, name: "" };

function getDefaultFetchableItems<T>(): IFetchableItems<T> {
	return {
		items: [],
		fetchStatus: FetchStatus.Empty
	};
}

function getDefaultFetchableItem<T>(defaultItem: T): IFetchableItem<T> {
	return {
		item: defaultItem,
		fetchStatus: FetchStatus.Empty
	};
}

export const defaultEpmState: IEpmState = {
	initialized: false,
	environment: getDefaultFetchableItem({
		userInfo: null,
		defaultOrganizationId: null
	} as IEnvironment),
	terms: getDefaultFetchableItem({} as Dictionary<string>),
	plan: {
		organizations: getDefaultFetchableItems(),
		isLoaded: false,
		planSets: getDefaultFetchableItems(),
		leaders: getDefaultFetchableItems(),
		focusAreas: getDefaultFetchableItems(),
		functionAreas: getDefaultFetchableItems(),
		progressScales: getDefaultFetchableItems(),
		components: getDefaultFetchableItems(),
		metricStatuses: getDefaultFetchableItems(),
		metricPeriods: getDefaultFetchableItems(),
		focus: {
			isLoaded: false
		}
	},
	planFocus: {
		selectedLeaders: [],
		selectedOrganizations: [],
		selectedPlanSet: EMPTY_PLAN_SET,
		overview: {
			refreshPerformanceWidget: true,
			refreshActionItemsRequireAttentionWidget: true,
			refreshActionStepsStartingSoonWidget: true,
			refreshMetricStatusWidget: true,
			refreshMyPlanItemWidget: true
		},
		actionSteps: {
			selectedFocusAreas: [],
			selectedFunctionAreas: [],
			selectedProgressScales: [],
			refreshTable: true,
			showArchived: false,
			resetTableSelection: false
		},
		goals: {
			selectedFocusAreas: [],
			selectedFunctionAreas: [],
			selectedProgressScales: [],
			refreshTable: true,
			showArchived: false,
			resetTableSelection: false
		},
		initiatives: {
			selectedComponents: [],
			selectedMetricStatuses: [],
			selectedProgressScales: [],
			selectedMetricPeriods: [],
			refreshTable: true,
			rerenderTable: false,
			showArchived: false,
			resetTableSelection: false
		}
	},
	planEnterprise: {
		selectedOrganization: undefined,
		selectedPlanSet: EMPTY_PLAN_SET,
		goals: {
			showArchived: false,
			refreshTable: true
		},
		initiatives: {
			showArchived: false,
			refreshTable: true
		},
		actionSteps: {
			showArchived: false,
			refreshTable: true
		}
	}
};

export interface IEpmState {
	initialized: boolean;
	environment: IFetchableItem<IEnvironment>;
	terms: IFetchableItem<Dictionary<string>>;
	plan: {
		organizations: IFetchableItems<IOrganization>;
		isLoaded: boolean;
		planSets: IFetchableItems<IPlanSet>;
		leaders: IFetchableItems<IUser>;
		focusAreas: IFetchableItems<IIdentifiableWithCaption>;
		functionAreas: IFetchableItems<IIdentifiableWithCaption>;
		progressScales: IFetchableItems<IColoredDataDto>;
		components: IFetchableItems<IIdentifiableWithCaption>;
		metricStatuses: IFetchableItems<IIdentifiableWithCaption>;
		metricPeriods: IFetchableItems<IIdentifiableWithCaption>;
		focus: {
			isLoaded: boolean;
		};
	};
	planFocus: IEpmStatePlanFocus;
	planEnterprise: IEmpStatePlanEnterprise;
}

export interface IEmpStatePlanEnterprise {
	selectedOrganization?: IOrganization;
	selectedPlanSet: IPlanSet;
	goals: IEpmStatePlanTable;
	initiatives: IEpmStatePlanTable;
	actionSteps: IEpmStatePlanTable;
}

export interface IEpmStatePlanFocus {
	selectedLeaders: IUser[];
	selectedOrganizations: IOrganization[];
	selectedPlanSet: IPlanSet;
	overview: IEpmStatePlanFocusOverview;
	actionSteps: IEpmStatePlanFocusActionSteps;
	goals: IEpmStatePlanFocusGoals;
	initiatives: IEpmStatePlanFocusInitiatives;
}

interface IEpmStatePlanTable {
	refreshTable: boolean;
	showArchived: boolean;
}

interface IEpmStatePlanTableWithFilterPanel extends IEpmStatePlanTable {
	resetTableSelection: boolean;
}

interface IEpmStatePlanFocusActionSteps extends IEpmStatePlanTableWithFilterPanel {
	selectedFocusAreas: IIdentifiableWithCaption[];
	selectedFunctionAreas: IIdentifiableWithCaption[];
	selectedProgressScales: IIdentifiableWithCaption[];
}

interface IEpmStatePlanFocusGoals extends IEpmStatePlanTableWithFilterPanel {
	selectedFocusAreas: IIdentifiableWithCaption[];
	selectedFunctionAreas: IIdentifiableWithCaption[];
	selectedProgressScales: IIdentifiableWithCaption[];
}

interface IEpmStatePlanFocusInitiatives extends IEpmStatePlanTableWithFilterPanel {
	selectedComponents: IIdentifiableWithCaption[];
	selectedMetricStatuses: IIdentifiableWithCaption[];
	selectedProgressScales: IIdentifiableWithCaption[];
	selectedMetricPeriods: IIdentifiableWithCaption[];
	rerenderTable: boolean;
}

export interface IEpmStatePlanFocusOverview {
	refreshPerformanceWidget: boolean;
	refreshActionItemsRequireAttentionWidget: boolean;
	refreshActionStepsStartingSoonWidget: boolean;
	refreshMetricStatusWidget: boolean;
	refreshMyPlanItemWidget: boolean;
}
