import { IEpmConfiguration } from "@common/common-types";
import { RootState } from "@redux/store";
import { IEpmState } from "@redux/defaultStates/defaultEpmState";

export function isInsightsEpmEnabled(epmCfg: IEpmConfiguration | null): boolean {
	return epmCfg !== null && epmCfg?.insightsEpmEnabled === true;
}

export function trimTrailingSlash(str: string): string {
	return str.replace(/\/$/, "");
}

export function getEpmState(state: RootState | IEpmState): IEpmState {
	if ("epm" in state) {
		return state.epm as IEpmState;
	}

	return state as IEpmState;
}

let epmApiPath: string = "";
export function buildEpmApiUrl(endpoint: string): string {
	return `${trimTrailingSlash(epmApiPath)}/${endpoint}`;
}

export function storeEpmApiPath(value: string) {
	epmApiPath = value;
}
