import {
	EpmEnterpriseActionStepsTab,
	EpmEnterpriseGoalTab,
	EpmEnterpriseInitiativeTab,
	EpmEnterprisePage,
	EpmFocusTab
} from "@epm/epmModel";

export class EpmRouter {
	public static readonly GOAL_ID = "goalId";
	public static readonly INITIATIVE_ID = "initiativeId";
	public static readonly ACTION_STEP_ID = "actionStepId";

	private static readonly TAB_ID_PLACEHOLDER = ":tabId";
	private static readonly GOAL_ID_PLACEHOLDER = `:${EpmRouter.GOAL_ID}`;
	private static readonly INITIATIVE_ID_PLACEHOLDER = `:${EpmRouter.INITIATIVE_ID}`;
	private static readonly ACTION_STEP_ID_PLACEHOLDER = `:${EpmRouter.ACTION_STEP_ID}`;

	public static readonly ROOT = "achieve";
	public static readonly FOCUS = "focus";
	public static readonly FOCUS$TAB_ID = `${EpmRouter.FOCUS}/${EpmRouter.TAB_ID_PLACEHOLDER}`;
	public static readonly PLANNING = "planning";
	public static readonly PLANNING$GOALS = `${EpmRouter.PLANNING}/${EpmEnterprisePage.Goals}`;
	public static readonly PLANNING$GOALS$GOAL_ID = `${EpmRouter.PLANNING$GOALS}/${EpmRouter.GOAL_ID_PLACEHOLDER}`;
	public static readonly PLANNING$GOALS$GOAL_ID$TAB_ID = `${EpmRouter.PLANNING$GOALS$GOAL_ID}/${EpmRouter.TAB_ID_PLACEHOLDER}`;
	public static readonly PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID = `${EpmRouter.PLANNING$GOALS$GOAL_ID}/${EpmEnterprisePage.Initiatives}/${EpmRouter.INITIATIVE_ID_PLACEHOLDER}`;
	public static readonly PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$TAB_ID = `${EpmRouter.PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID}/${EpmRouter.TAB_ID_PLACEHOLDER}`;
	public static readonly PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$ACTION_STEPS$ACTION_STEP_ID = `${EpmRouter.PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID}/${EpmEnterprisePage.ActionSteps}/${EpmRouter.ACTION_STEP_ID_PLACEHOLDER}`;
	public static readonly PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$ACTION_STEPS$ACTION_STEP_ID$TAB_ID = `${EpmRouter.PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$ACTION_STEPS$ACTION_STEP_ID}/${EpmRouter.TAB_ID_PLACEHOLDER}`;

	static getFocus() {
		return `/${EpmRouter.ROOT}/${EpmRouter.FOCUS}`;
	}

	public static getFocusTab(tab: EpmFocusTab): string {
		return `/${EpmRouter.ROOT}/${EpmRouter.FOCUS$TAB_ID.replace(EpmRouter.TAB_ID_PLACEHOLDER, tab)}`;
	}

	public static getPlanningGoalTab(goalId: number | string, tab: EpmEnterpriseGoalTab): string {
		return `/${EpmRouter.ROOT}/${EpmRouter.PLANNING$GOALS$GOAL_ID$TAB_ID.replace(EpmRouter.GOAL_ID_PLACEHOLDER, goalId.toString()).replace(EpmRouter.TAB_ID_PLACEHOLDER, tab)}`;
	}

	static getPlanningGoals() {
		return `/${EpmRouter.ROOT}/${EpmRouter.PLANNING$GOALS}`;
	}

	public static getPlanningInitiativeTab(
		goalId: number | string,
		initiativeId: number | string,
		tab: EpmEnterpriseInitiativeTab
	): string {
		return `/${EpmRouter.ROOT}/${EpmRouter.PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$TAB_ID.replace(
			EpmRouter.GOAL_ID_PLACEHOLDER,
			goalId.toString()
		)
			.replace(EpmRouter.INITIATIVE_ID_PLACEHOLDER, initiativeId.toString())
			.replace(EpmRouter.TAB_ID_PLACEHOLDER, tab)}`;
	}

	public static getPlanningActionStepTab(
		goalId: number | string,
		initiativeId: number | string,
		actionStepId: number | string,
		tab: EpmEnterpriseActionStepsTab
	): string {
		return `/${EpmRouter.ROOT}/${EpmRouter.PLANNING$GOALS$GOAL_ID$INITIATIVES$INITIATIVE_ID$ACTION_STEPS$ACTION_STEP_ID$TAB_ID.replace(
			EpmRouter.GOAL_ID_PLACEHOLDER,
			goalId.toString()
		)
			.replace(EpmRouter.INITIATIVE_ID_PLACEHOLDER, initiativeId.toString())
			.replace(EpmRouter.ACTION_STEP_ID_PLACEHOLDER, actionStepId.toString())
			.replace(EpmRouter.TAB_ID_PLACEHOLDER, tab)}`;
	}
}
