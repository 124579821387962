import "@blueprintjs/select/lib/css/blueprint-select.css";
import { Fragment, useCallback, useEffect, useState } from "react";
import { InputGroupProps, MenuDivider, PopoverProps } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import { ItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer";
import { IMenuItemByProduct, ISearchResultProductModel, SearchMenuItem } from "./TopNavigationModel";
import { menuApi } from "../menuApi";
import { useAppDispatch, useAppSelector } from "@redux/reduxHooks";
import { ICommonMenuProps, IMenuItemDto, MenuActionType, MenuNodeType } from "../MenuModel";
import MedeMenuItem, { IMenuNavigationParams, performMenuNavigation } from "../MedeMenuItem";
import { useNavigate } from "react-router-dom";
import { MedeIcon } from "@mede/react-library/components";
import { highlightStr } from "@mede/react-library/utils";
import { faChevronsRight, faSearch } from "@mede/react-library/icons";

const ITEM_SEARCH_LINK_TYPE: string = "ItemContentSearchLink";

export interface ISearchProductItemsInputProps extends ICommonMenuProps {
	roundInput?: boolean;
	className?: string;
	portalClassName?: string;
	inputPlaceholder?: string;
}

export default function SearchProductItemsInput(props: Readonly<ISearchProductItemsInputProps>) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [resultItems, setResultItems] = useState<Array<SearchMenuItem>>([]);
	const [activeItem, setActiveItem] = useState<SearchMenuItem | null>(null);
	const [queryStr, setQueryStr] = useState<string>("");
	const clientTitle = useAppSelector(s => s.app.configuration.clientTitle);
	const isLoading = useAppSelector(s => s.menu.isLoading);
	const { container } = props;

	useEffect(() => {
		setResultItems([]);
		setActiveItem(null);
		if (!queryStr.length) {
			return;
		}

		menuApi.searchMenuItems(queryStr).then(data => {
			setResultItems(createSearchMenuResultItems(data));
		});
	}, [queryStr]);

	const itemRender = useCallback(
		(item: SearchMenuItem, props: ItemRendererProps) => {
			if (item.disabled) {
				return <MenuDivider className="top-nav" title={item.caption.toUpperCase()} key={"div-" + item.caption} />;
			}

			const navigationParams = createNavigationParams(item);
			const key = item.productId + item.path;
			if (item.itemType === ITEM_SEARCH_LINK_TYPE) {
				return (
					<Fragment key={"frag-" + key}>
						<MenuDivider key={"div-" + key} />
						<MedeMenuItem
							{...navigationParams}
							text={
								<>
									{item.caption} <MedeIcon icon={faChevronsRight} />
								</>
							}
							key={key}
							beforeNavigation={() => closeLeftMenu(container)}
							active={props.modifiers.active}
						/>
					</Fragment>
				);
			}

			return (
				<MedeMenuItem
					{...navigationParams}
					text={highlightStr(item.caption, props.query)}
					key={key}
					beforeNavigation={() => closeLeftMenu(container)}
					active={props.modifiers.active}
				/>
			);
		},
		[clientTitle]
	);

	function onActiveItemChanged(item: SearchMenuItem | null) {
		setActiveItem(item);
	}

	let queryTimeout: number;
	function onQueryChange(query: string) {
		if (queryTimeout != null) {
			clearTimeout(queryTimeout);
		}

		queryTimeout = window.setTimeout(() => {
			setQueryStr(query);
		}, 300);
	}

	function onItemSelect(item: SearchMenuItem) {
		if (item.actionType === MenuActionType.OpenInPopup) {
			window.open(item.url, "_blank");
			return;
		}

		const navigationParams = createNavigationParams(item);
		performMenuNavigation(navigationParams, dispatch, navigate);

		closeLeftMenu(container);
	}

	const inputProps: InputGroupProps = {
		id: "menu-search-input",
		className: props.className,
		leftElement: <MedeIcon icon={faSearch} className="search-icon" />,
		round: props.roundInput,
		placeholder: props.inputPlaceholder
	};

	const popoverProps: PopoverProps = {
		minimal: true,
		portalContainer: props.container,
		onClose: () => setResultItems([]),
		portalClassName: props.portalClassName,
		popoverClassName: "result-menu-items-popover"
	};

	return (
		<Suggest
			onActiveItemChange={onActiveItemChanged}
			activeItem={activeItem}
			items={resultItems}
			itemRenderer={itemRender}
			itemDisabled={item => item.disabled}
			inputValueRenderer={() => ""}
			disabled={isLoading}
			inputProps={inputProps}
			onItemSelect={item => onItemSelect(item)}
			resetOnClose={true}
			resetOnSelect={true}
			resetOnQuery={true}
			onQueryChange={query => onQueryChange(query)}
			popoverProps={popoverProps}
		/>
	);
}

function getItemPath(item: SearchMenuItem): string[] {
	if (item.pathList == null || item.itemType === ITEM_SEARCH_LINK_TYPE) {
		return [];
	}

	return item.pathList.length > 1 ? item.pathList.slice(0, -1) : item.pathList;
}

function createNavigationParams(item: SearchMenuItem): IMenuNavigationParams {
	const menuItem = createMenuItemDto(item);

	return {
		data: menuItem,
		path: getItemPath(item),
		productId: item.productId,
		navigationType: "Search"
	};
}

function createMenuItemDto(item: SearchMenuItem): IMenuItemDto {
	const menuItem = { url: item.url, actionType: item.actionType, caption: item.caption } as IMenuItemDto;
	if (item.itemType && Object.keys(MenuNodeType).includes(item.itemType)) {
		menuItem.itemType = item.itemType as MenuNodeType;
	}

	return menuItem;
}

function createSearchMenuResultItems(model: Array<ISearchResultProductModel>): SearchMenuItem[] {
	const result = Array<SearchMenuItem>();
	model.forEach((product: ISearchResultProductModel): void => {
		result.push(
			new SearchMenuItem({
				caption: product.productCaption,
				productId: product.productId,
				disabled: true
			} as SearchMenuItem)
		);
		product.menuItemsByProduct.forEach((item: IMenuItemByProduct) => {
			result.push(
				new SearchMenuItem({
					caption: item.caption,
					productId: product.productId,
					locationType: item.openedLocationType,
					disabled: false,
					actionType: item.actiontype,
					itemType: item.itemType == null || item.itemType.length === 0 ? item.nodeType : item.itemType,
					level: item.level,
					url: item.url,
					pathList: item.pathList
				})
			);
		});
	});

	return result;
}

function closeLeftMenu(container: HTMLElement | undefined) {
	const event = new KeyboardEvent("keydown", { key: "Escape", bubbles: true });
	(container ?? document)?.querySelector(".left-menu-drawer")?.dispatchEvent(event);
}
